var initialState = {
    primaryLanguage:"",
    selectedLang: initialState?.primaryLanguage,
    menuList:[],
    sideDishList:[],
    itemList:[],
    categoryList:[],
    subCategoryList:{},
    companyInfo:{},
};

export const globalStore = (state = initialState, action) => {


    switch (action.type) {
        case 'SET_MENU_DATA':
            return {
                ...state,
                menuList: action?.menuList
            }

            case 'SET_CATEGORY_DATA':
            return {
                ...state,
                categoryList: action?.categoryList
            }

            case 'SET_SUBCATEGORY_DATA':
            return {
                ...state,
                subCategoryList: action?.subCategoryList
            }

            case 'SET_SIDEDISH_DATA':
            return {
                ...state,
                sideDishList: action?.sideDishList
            }

            case 'SET_ITEMS_DATA':
            return {
                ...state,
                itemList: action?.itemList
            }

            case 'SET_COMPANY_DATA':
            return {
                ...state,
                companyInfo: action?.companyInfo
            }
            case 'SET_PRUMARY_LANG':
            return {
                ...state,
                primaryLanguage: action?.primaryLanguage
            }

            case 'SET_SELECTED_LANG':
            return {
                ...state,
                selectedLang: action?.selectedLang
            }
            
        default: return state;
    }
}

