import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Loader from '../Shared/Loader/Loader';

const LandingApp = React.lazy(() =>
  import(/*webpackChunkName: "LandingApp"*/ './Landing/LandingApp')
);
const MenuApp = React.lazy(() =>
  import(/*webpackChunkName: "MenuApp"*/ './Menu/MenuApp')
);

const AppSwitch = () => {

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route index path='*' element={<LandingApp />} />
          <Route path=':shareLink/*' element={<MenuApp  />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppSwitch;
